export var IS_RELATED_POSTS_ENABLED = 'blog-isRelatedPostsEnabled';
export var IS_RELATED_POSTS_ENABLED_PATH = "style.booleans." + IS_RELATED_POSTS_ENABLED;
export var IS_RELATED_POSTS_ENABLED_MOBILE = 'blog-mobile-isRelatedPostsEnabled';
export var IS_RELATED_POSTS_ENABLED_MOBILE_PATH = "style.booleans." + IS_RELATED_POSTS_ENABLED_MOBILE;
export var IS_RELATED_POSTS_LABEL_ENABLED = 'blog-isRelatedPostsLabelEnabled';
export var IS_RELATED_POSTS_LABEL_ENABLED_PATH = "style.booleans." + IS_RELATED_POSTS_LABEL_ENABLED;
export var IS_RELATED_POSTS_LABEL_ENABLED_MOBILE = 'blog-mobile-isRelatedPostsLabelEnabled';
export var IS_RELATED_POSTS_LABEL_ENABLED_MOBILE_PATH = "style.booleans." + IS_RELATED_POSTS_LABEL_ENABLED_MOBILE;
export var IS_SEE_ALL_POSTS_LINK_ENABLED = 'blog-isSeeAllPostsLinkEnabled';
export var IS_SEE_ALL_POSTS_LINK_ENABLED_PATH = "style.booleans." + IS_SEE_ALL_POSTS_LINK_ENABLED;
export var IS_SEE_ALL_POSTS_LINK_ENABLED_MOBILE = 'blog-mobile-isSeeAllPostsLinkEnabled';
export var IS_SEE_ALL_POSTS_LINK_ENABLED_MOBILE_PATH = "style.booleans." + IS_SEE_ALL_POSTS_LINK_ENABLED_MOBILE;
export var RELATED_POSTS_LABEL = 'relatedPostsLabel';
export var RELATED_POSTS_LABEL_MOBILE = 'mobile-relatedPostsLabel';
export var RELATED_POSTS_LABEL_PATH = "style.numbers." + RELATED_POSTS_LABEL;
export var RELATED_POSTS_LABEL_MOBILE_PATH = "style.numbers." + RELATED_POSTS_LABEL_MOBILE;
export var RELATED_POSTS_LABEL_OPTIONS = { related: 0, recent: 1 };
export var RELATED_POSTS_LABEL_OPTIONS_BY_ID = { 0: 'related', 1: 'recent' };
export var RELATED_POSTS_LABEL_STRING_KEY = {
    0: 'related-posts.title',
    1: 'recent-posts.title',
};
export var RELATED_POSTS_LABEL_OPTION = RELATED_POSTS_LABEL_OPTIONS.related;
