export const getUserBadgeLabel = ({ t, role }) => {
  switch (role) {
    case 'owner':
      return t('badge.owner');
    case 'admin':
      return t('badge.admin');
    case 'writer':
      return t('badge.writer');
    case 'editor':
      return t('badge.editor');
    default:
      return;
  }
};
