import {
  createAction,
  getCategoryIds,
} from '@wix/communities-blog-client-common';
import { normalizePosts } from '../services/post-utils';

export const SET_POSTS = 'posts/SET';

const setNormalizedPosts = createAction(SET_POSTS);

const setPosts =
  (posts = []) =>
  (dispatch, getState) => {
    const normalizedPosts = normalizePosts({
      state: getState(),
      posts,
      blogCategoryIds: getCategoryIds(getState()),
    });
    dispatch(setNormalizedPosts(normalizedPosts));
    return normalizedPosts;
  };

export default setPosts;
