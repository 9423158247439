import React from 'react';

export const EditorSmallIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path d="M3.0299,4.05263158 C7.59702941,1.31578947 11.4029706,1.31578947 15.9701,4.05263158 C16.0788412,11 16.2419529,14.3157895 9.5,17 C2.75804706,14.3157895 2.92115882,11 3.0299,4.05263158 Z M9.39332888,11.2792808 C9.45851262,11.2381732 9.54151575,11.2381732 9.60669949,11.2792808 L11.6016734,12.5373957 C11.7030093,12.6013024 11.8276546,12.6164227 11.9413311,12.5785984 C12.1509459,12.508852 12.2643317,12.2823849 12.1945852,12.07277 L11.4749606,9.91002155 L13.4066196,8.57961581 C13.5127893,8.50649273 13.5772092,8.38668249 13.5796591,8.25779106 C13.5838574,8.03691706 13.408207,7.85445994 13.1873329,7.85026169 L10.7205982,7.80337534 L9.87345622,5.59604095 C9.83283079,5.4901863 9.74919031,5.40654582 9.64333566,5.36592038 C9.43708931,5.28676612 9.20572641,5.38979461 9.12657215,5.59604095 L8.27943019,7.80337534 L5.81269542,7.85026169 C5.68380399,7.85271159 5.56399375,7.91713145 5.49087067,8.02330121 C5.36556375,8.20523836 5.41147163,8.45430889 5.59340879,8.57961581 L7.52506779,9.91002155 L6.80544312,12.07277 C6.76761883,12.1864466 6.78273912,12.3110919 6.84664582,12.4124278 C6.96448715,12.599287 7.21149576,12.655237 7.39835499,12.5373957 L9.39332888,11.2792808 Z" />
  </svg>
);
