import { __assign, __spreadArrays } from "tslib";
import { isEmpty, has, get, set, omit } from 'lodash';
var isLegacyImage = function (entity) { return entity.type === 'IMAGE'; };
var isImage = function (entity) { return entity.type === 'wix-draft-plugin-image'; };
var isGallery = function (entity) { return entity.type === 'wix-draft-plugin-gallery'; };
var isSmallSizeGridGalleryWithoutPerRowValue = function (entity) {
    return isGallery(entity) &&
        !has(entity, 'data.styles.numberOfImagesPerRow') &&
        get(entity, 'data.styles.galleryLayout') === 2 &&
        get(entity, 'data.config.size') === 'small';
};
var isVideoEmbed = function (entity) { return entity.type === 'VIDEO-EMBED'; };
var isLink = function (entity) { return entity.type === 'LINK'; };
var isEntityWithoutData = function (entity) {
    return (isLegacyImage(entity) || isVideoEmbed(entity)) && isEmpty(entity.data);
};
var isImageWithoutSrc = function (entity) {
    return isLegacyImage(entity) && !get(entity, 'data.src');
};
var isImageWithoutConfig = function (entity) {
    return isImage(entity) && !get(entity, 'data.config');
};
var isVideoWithoutUrl = function (entity) {
    return isVideoEmbed(entity) && !get(entity, 'data.url');
};
var isLinkWithoutUrl = function (entity) { return isLink(entity) && !get(entity, 'data.url'); };
var isCorrupted = function (entity) {
    return isEntityWithoutData(entity) ||
        isImageWithoutSrc(entity) ||
        isVideoWithoutUrl(entity);
};
var hasOneOfEntities = function (block, entityKeys) {
    return entityKeys.indexOf(get(block, 'entityRanges[0].key')) > -1;
};
function isVersionBefore(before, version) {
    var _a;
    if (isEmpty(version)) {
        return true;
    }
    if (before === version) {
        return false;
    }
    _a = [before.split('.'), version.split('.')], before = _a[0], version = _a[1];
    for (var i = 0; i < 3; i++) {
        var diff = Number(before[i] || 0) - Number(version[i] || 0);
        if (diff > 0) {
            return true;
        }
        if (diff < 0) {
            return false;
        }
    }
    return false;
}
var getCorrupted = function (entityMap) {
    return Object.keys(entityMap).reduce(function (acc, key) {
        var entity = entityMap[key];
        if (isCorrupted(entity)) {
            acc.corruptedEntityKeys.push(Number(key));
        }
        else if (isLinkWithoutUrl(entity)) {
            acc.invalidLinkEntityKeys.push(Number(key));
        }
        return acc;
    }, { corruptedEntityKeys: [], invalidLinkEntityKeys: [] });
};
var removeCorrupted = function (corruptedEntityKeys, content) {
    var entityMap = content.entityMap;
    var isValidEntityRange = function (entityRange) {
        var entity = entityMap[entityRange.key];
        return entity && !isLinkWithoutUrl(entity);
    };
    return content.blocks.reduce(function (acc, block) {
        if (hasOneOfEntities(block, corruptedEntityKeys)) {
            return acc;
        }
        if (Array.isArray(block.entityRanges) && block.entityRanges.length) {
            block.entityRanges = block.entityRanges.filter(isValidEntityRange);
        }
        acc.push(block);
        return acc;
    }, []);
};
var tempFix = function (entityMap, version) {
    Object.keys(entityMap).forEach(function (key) {
        var entity = entityMap[key];
        if (isGallery(entity)) {
            var imageMargin = get(entity, 'data.styles.imageMargin', null);
            if (imageMargin && typeof imageMargin === 'string') {
                entity.data.styles.imageMargin = parseInt(imageMargin, 10);
            }
        }
        if (isImageWithoutConfig(entity)) {
            entity.type = 'IMAGE';
        }
        else if (isSmallSizeGridGalleryWithoutPerRowValue(entity) &&
            isVersionBefore('6.9.2', version)) {
            set(entity, 'data.styles.numberOfImagesPerRow', 2);
        }
    });
    return entityMap;
};
/**
 * Removes invalid entities which were corrupted by bugs in wix-rich-content-editor
 */
export default function removeCorruptedEntities(content) {
    if (!content) {
        return content;
    }
    var _a = getCorrupted(content.entityMap), corruptedEntityKeys = _a.corruptedEntityKeys, invalidLinkEntityKeys = _a.invalidLinkEntityKeys;
    return __assign(__assign({}, content), { blocks: removeCorrupted(corruptedEntityKeys, content), entityMap: tempFix(omit(content.entityMap, __spreadArrays(corruptedEntityKeys, invalidLinkEntityKeys)), content.VERSION) });
}
